    <!-- 热点资讯-->

<template>
    <div class="Hotnews mar-b-80">
        <div class="flex content bann-item " @click="toOpenWin(bannerList[bannerIdx].url)">
            <div>
                <el-carousel
                    indicator-position="none"
                    ref="carousel"
                    arrow="never"
                    @change="change"
                    class="banner-box"
                >
                    <el-carousel-item v-for="(item, i) in bannerList" :key="i">
                        <div
                            class="content flex po-r"
                            
                        >
                            <div class="img-box">
                                <img
                                    :src="
                                        require('@/assets/img/hotnews/' +
                                            item.pic +
                                            '.png')
                                    "
                                    width="722px"
                                    height="480px"
                                />
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="text-box fl1 po-r">
                <img src="@/assets/img/headlines-icon.png" width="114px" />
                <p class="title">
                    {{ bannerList[bannerIdx].title }}
                </p>
                <p class="info">
                    {{ bannerList[bannerIdx].info }}
                </p>
                <p class="time">{{ bannerList[bannerIdx].time }}</p>
                <div class="num-box">
                <span>{{ bannerIdx + 1 }}</span
                >/3
            </div>
            </div>
            
        </div>
        <div class="content">
            <img src="@/assets/img/news-title.png" height="40px" />
        </div>
        <transition name="fade">
            <div v-show="showList" class="list-box content">
                <div
                    class="flex list-item"
                    v-for="(item, idx) in lists"
                    :key="idx"
                    @click="toOpenWin(item.url)"
                >
                    <img
                        v-if="item.pic"
                        :src="
                            require('@/assets/img/hotnews/' + item.pic + '.png')
                        "
                        width="240px"
                        height="180px"
                    />
                    <div class="text-box fl1">
                        <p class="title">
                            {{ item.title }}
                        </p>
                        <p class="info">
                            {{ item.info }}
                        </p>
                        <p class="time">{{ item.time }}</p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="content t-al-c">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="5"
                :total="15"
                :current-page.sync="pageNum"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
 import {carousel,carouselItem,pagination} from "element-ui"
export default {
  name: 'Hotnews',
  components: {
       elCarousel:carousel,
      elCarouselItem:carouselItem,
      elPagination:pagination
  },
  methods:{
      change(idx){
          this.bannerIdx = idx;
      },
      toOpenWin(url){
          window.open(url)
      }
  },
  computed:{
    //   lists(){
    //       return this.listsMap[this.pageNum]
    //   },
  },
  created(){
      this.lists = this.listsMap[this.pageNum]
  },
  watch:{
      pageNum(){
          this.showList = false;
          setTimeout(()=>{
               this.showList = true;
               this.lists = this.listsMap[this.pageNum]
          },300)
          
      },
  },
  data(){
      return {
          bannerIdx:0,
          lists:[],
          showList:true,
          pageNum:1,
          bannerList:[
              {title:'以创新博未来，优选好生活开创平台交易新时代',info:'优选好生活合伙人商学院启动仪式——“数字共谱好生活”2020优选好生活战略大会在北京隆重召开。此次会议上，以集团领导为核心的智囊团，与在座同事共同分享了优选好生活转型的时代背景、最新合伙人理念、互联网用户营销增长策略以及好生活技术产品蓝图和迭代计划。优选好生活合伙人商学院启动仪式——“数字共谱好生活”2020优选好生活战略大会在北京隆重召开。此次会议上，以集团领导为核心的智囊团，与在座同事共同分享了优选好生活转型的时代背景、最新合伙人理念、互联网用户营销增长策略以及好生活技术产品蓝图和迭代计划。',url:'http://www.fangchan.com/news/132/2020-11-26/6737616521985855596.html',time:'2020.11.26',pic:'hotnews-img1'},
              {title:'优选好生活科技荣获CFS第九届中国财经峰会“2020商业模式引领奖”',info:' CFS第九届中国财经峰会商业模式引领奖颁奖典礼于2020年8月27日-28日在上海举行,优选好生活科技奖项之路再创新高,荣膺CFS第九届中国财经峰会"2020商业模式引领奖"。',url:'https://news.163.com/20/0828/16/FL4N4BRP00019OH3.html',time:'2020.8.28',pic:'hotnews-img2'},
              {title:'优选好生活发布"城市联席总经理"招募令，撬动万亿级蓝海市场',info:' 优选好生活全新出发，开拓新机遇，在全国范围内启动"城市联席总经理"招募计划，欲携手合作伙伴撬动万亿级蓝海市场，释放行业经济发展新活力，提升自我生存能力与竞争能力。',url:'http://biz.ifeng.com/c/80m29MX0Qhj',time:'2020.10.22',pic:'hotnews-img3'},
          ],
        listsMap:{
            1:[{
            pic: "hotnews-img4",
            url: "https://finance.ifeng.com/c/82Ic3k9tTOK",
            title: "产业数字化呈现蓝海之势，优选好生活推动非标领域数字转型",
            info: "以AI、大数据、云计算、物联网等技术为代表的第四次工业革命，正在展现出对传统和新兴产业强大的推动力、重塑力，产业数字化也呈现一片蓝海之势，逐步成为中国经济新的增长极。据统计，从2011年到2019年我国数字经济增加值从9.5万亿增加到35.8万亿，占GDP比重从20.3％提升到36.2％，数字经济张力尽显。",
           time: "2020.12.18"
        }, {
            pic: "hotnews-img5",
            url: "https://news.163.com/20/1210/15/FTGF06SF00019OH3.html",
            title: "抢占数字化转型先机，优选好生活全面推动产业升级",
            info: "数字化浪潮在全球范围内全面展开，新一轮科技革命和产业变革正加速演进，数字技术成为传统产业打破当前瓶颈的关键钥匙。优选好生活科技（珠海）有限公司（简称优选好生活）顺应数字化浪潮，统筹数据管理，推动实体产业数字化转型，培育中国经济新的内生增长动力，聚焦非标赛道，拟撬动万亿级非标大宗商品家庭消费蓝海。",
            time: "2020.12.10",
        }, {
            pic: "hotnews-img6",
            url: "https://www.sohu.com/a/433681698_120610945",
            title: "数字共谱好生活，优选好生活合伙人引领行业潮流 ",
            info: "11月21日，以“数字共谱好生活”为主题的优选好生活培训大会在北京高和蓝峰大厦成功举办，同时“好生活合伙人商学院”（也称“合生活商学院”）正式启动。此次培训大会优选好生活集团总部5位资深大咖采用线下授课+线上直播的形式，向全国各地5大区域公司负责人、城市公司负责人以及集团总部全体在内的共400多名员工，诠释了公司的最新战略规划、合伙人运营模式，并明确了2021年的重点工作方向。",
           time: "2020.11.21"
        }, {
            pic: "hotnews-img7",
            url: "http://www.abi.com.cn/news/htmfiles/2020-11/231946.shtml",
            title: "优选好生活创新求变，合伙人新模式助力推动产业变革",
            info: "作为一家互联网金融科技服务企业，优选好生活以“科技+金融”赋能G端、B端，助力政府、企业解决便民施政、经济发展过程中的难题，针对C端用户，优选好生活基于“平台+个人”的商业模式，于今年9月正式启动“好生活合伙人”计划，全面打造以合伙人为销售核心，以全链条服务为核心竞争力的开放型非标大宗商品交易及服务平台。在此定位基调下，优选好生活重视科研技术，以技术赋能平台，为用户提供多元化产品和服务。",
           time: "2020.11.13"
        }, {
            pic: "hotnews-img8",
            url: "http://news.hexun.com/2020-08-28/201962650.html",
            title: "再创佳绩！优选好生活荣获CFS中国财经峰会“2020商业模式引领奖”",
            info: "在8月28日举办的颁奖典礼上,优选好生活荣誉之路再创佳绩,一举荣获“2020商业模式引领奖”。值得注意的是,本次获奖评选,组委会邀请了知名研究机构、咨询公司、专家学者、媒体领袖和部分往届获奖代表共同组成评审委员会,根据评审委员会成员通过的综合评估体系,进行可量化的数据比对,最终评选出获奖名单。可以看到,优选好生活创新商业模式已经得到了业内高度认可。",
           time: "2020.8.28"
        }],
        2:[
            {
            pic: "hotnews-img9",
            url: "https://baijiahao.baidu.com/s?id=1676002162971544029&wfr=spider&for=pc",
            title: "优选好生活×壹链盟｜佣金秒结当日到账，助力渠道闪佣提现",
            info: "针对项目渠道公司佣金回款慢、资金占压高、融资效率慢、融资成本高等行业痛点，优选好生活科技联手壹链盟共同推出了「秒结秒付」保理服务产品，结佣交易安全可靠，有保障，高效助力渠道公司创收。",
           time: "2020.8.25"
        }, {
            pic: "hotnews-img10",
            url: "https://news.163.com/20/0722/11/FI4TEHFI00019OH3.html",
            title: "彰显平台企业责任,优选好生活派发“成交好礼” 关爱一线经纪人群体",
            info: "疫情过后，一度停滞的房产行业亟待新兴动力注入。一线经纪人愈发成为链条上最关键的齿轮，为房产业经济复苏加快资金流动。炎炎夏日，报备带看陪客户，楼盘门店多处跑，经纪人艳阳下工作成为常态，他们更需要外界的支持与鼓励。近日优选好生活特为一线渠道经纪人送去夏日补贴福利，防晒用品、橙蕉礼盒齐上阵，助力经纪人群体拓客开单。",
           time: "2020.7.22"
        }, {
            pic: "hotnews-img11",
            url: "https://news.163.com/20/0714/11/FHGA5HK200019OH3.html",
            title: "2020智纲智库大会召开，优选好生活分享非标大宗商品交易新模式",
            info: "智纲智库大会是由中国著名战略策划机构智纲智库发起创办，依托其26年来的社会公信力和广泛的政企资源搭建的信息交流和资源整合平台。结合智纲智库的核心优势和专业能力，大会以“解决问题”为导向，是中国首创且唯一的咨询式大会。此次大会上，优选好生活作为领先的非标大宗商品智慧交易平台代表为大家带来了业务板块的部署及未来宏图的展望。",
           time: "2020.7.10"
        }, {
            pic: "hotnews-img12",
            url: "https://news.163.com/20/0701/17/FGFGUM1R00019OH3.html",
            title: "缔造生态 创效未来 优选好生活秒结秒付产品上线启动会亮点多多",
            info: "缔造生态,创效未来。7月1日下午,优选好生活科技(珠海)有限公司联手壹链盟生态科技有限公司,在京召开秒结秒付产品上线启动会,优选好生活智慧交易平台动态结佣统计结果显示当日结佣高达1.5亿！科技+金融赋能,推动非标大宗商品行业新生态发展。",
           time: "2020.7.1"
        }, {
            pic: "hotnews-img13",
            url: "https://new.qq.com/rain/a/20200617A0PID200",
            title: "优选好生活平台全面升级，科技＋产品打造保险交易新业态",
            info: "作为一家以科技引领，以金融赋能的智慧交易平台，好生活平台率先提出的“非标大宗消费品交易”理念，凸显了企业全面打造新中产家庭资产配置平台的愿景。而保险作为新中产家庭资产配置中不可忽视的重要组成部分，受到越来越多家庭的关注。为此，好生活平台全力打造的保险业务板块，全方位满足消费者的保险需求，也为平台经纪人开拓更多展业渠道。",
           time: "2020.6.17"
        }],
        3:[
            {
            pic: "hotnews-img14",
            url: "https://finance.ifeng.com/c/7yRvMiPaySt",
            title: "优选好生活成立三周年，致力非标大宗商品行业焕新升级",
            info: "2020年6月6日，优选好生活成立三周年，从2017年至2020年，优选好生活为3000多万个中高净值家庭提供着全方位的配套服务。108%的用户复合增长率见证了优选好生活平台的高速成长。三年的时间，优选好生活虽然还不是一个成熟的企业，自身不断完善商业版图的过程，也已谱写出一段精彩的乐曲。",
           time: "2020.6.6"
        }, {
            pic: "hotnews-img15",
            url: "https://news.163.com/20/0601/13/FE1R0UCS00019OH3.html",
            title: "彰显行业担当，优选好生活平台2亿资金助力行业复苏，十日闪速垫佣破千万！",
            info: "疫情时代，各行各业均面临着行业复苏、经济回春这一前所未有的挑战，关于如何促进小微企业发展，保持经济活力成为了代表们热议的话题。会议期间，针对中小企业的减税降费、降低贷款门槛、放宽登记场所限制等利好政策陆续出台，让很多身处困境的小企业看到了希望。",
           time: "2020.6.1"
        }, {
            pic: "hotnews-img16",
            url: "http://house.hexun.com/2020-05-20/201388050.html",
            title: "优选好生活直播首秀引领营销新模式，多维度举措打造全能经纪人",
            info: "互联网+时代，社交平台展现出前所未有的热度和能量。今年疫情背景下，直播更是“一夜之间”成为各行各业借力的突破口。5月18日，优选好生活举办“全能经纪直播节”，不仅充分展示了品牌作为非标大宗消费品智慧交易平台的优势以及助力经纪人快速结佣、自我提升的几大利器，还联合跨界人气嘉宾将经纪人邀请至“聚光灯下”，展示成为新时代全能经纪人的“秘密”。",
           time: "2020.5.20"
        }, {
            pic: "hotnews-img17",
            url: "https://finance.ifeng.com/c/7w5ogjaHBg6",
            title: "科技赋能生态产业链，优选好生活荣获蓝筹年会“年度科技赋能创新平台”荣誉",
            info: "4月29日，第十七届（2020）蓝筹地产年会在线上云端进行了直播。本次大会的目光聚焦于中国经济及房地产行业发展，旨在共同探讨当下及未来房地产行业的应对和趋势。大会由经观新闻、经济观察报、经济观察网主办，共有超过100家主流媒体参与并进行立体化传播，极具前瞻性与深度。",
           time: "2020.4.30"
        }, {
            pic: "hotnews-img18",
            url: "https://new.qq.com/omn/20200331/20200331A0Q1YM00.html",
            title: "BTV新闻报道优选好生活，线上售楼处足不出户购满意好房",
            info: "随着互联网技术的迅猛发展以及5G技术的加持，线上营销已然成为现实。优选好生活开发的线上售楼处宣布正式上线，满足客户足不出户就能体验到网上 VR 看房、推荐、认购的一站式服务体验，为用户呈现全新看房模式，真实查看房屋的三维结构、户型朝向、装修内饰，户型细节一览无余，零距离感受样板间，切实解决疫情期间的看房难题，实现足不出户的真实看房体验，更享有个性化购房,推荐优惠等多项权益，满足不同客户的不同需求。",
           time: "2020.3.31"
        }]},
      }
  },
}
</script>
<style lang="scss" scoped>
.banner-box {
   width:722px;
}
.bann-item {
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(72, 152, 255, 0.16);
    border-radius: 8px;
    margin: 60px auto;
    cursor: pointer;

    .text-box {
        padding: 48px;
        padding-bottom: 0;
        padding-right: 44px;
        color: #002062;
        line-height: 34px;

        .info {
            height: 200px;
            font-size: 18px;
            padding-top: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin-bottom: 5px;
        }
        .title {
            font-size: 30px;
            font-weight: bold;
            line-height: 46px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-top: 25px;
        }
    }
}
.mar-b-80 {
    margin-bottom: 80px;
}

.time {
    font-size: 20px;
    color: #6679a1;
}
.num-box {
    position: absolute;
    bottom: 24px;
    right: 44px;
    font-size: 20px;
    font-weight: bold;
    color: #4898ff;
    line-height: 35px;
    letter-spacing: 1px;

    span {
        font-size: 30px;
    }
}
.list-box .list-item:last-child {
    border-bottom: 0;
}
.list-item {
    padding: 40px 0;
    border-bottom: 1px solid #dbdfe9;
    cursor: pointer;

    .text-box {
        padding-left: 30px;
    }
    .title {
        font-size: 22px;
        font-weight: bold;
        line-height: 29px;
        letter-spacing: 1px;
    }
    .info {
        line-height: 30px;
        margin-top: 16px;
        margin-bottom: 41px;
        font-size: 18px;
        color: #002062;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .time {
        line-height: 34px;
    }
}
.img-box {
    font-size: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
<style lang="scss">
.Hotnews {
    .el-carousel__container {
        height: 480px;
    }
    .el-pagination.is-background .el-pager li {
        font-size: 14px;
        color: #002062;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #4898ff;
    }
    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
        background: transparent;
    }
}
</style>
